<template>
    <div class="w-full h-full flex-none">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'CarousellItem'
    }
</script>