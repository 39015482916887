<script>
	import Hammer from 'hammerjs';

    export default {
		name: 'Touch',
		props: {
			gestures: {
				type: Array,
				required: true
			},
			options: {
				type: Object,
				default: () => ({})
			},
			stopPropagation: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				interaction: null
			};
		},
		watch: {
			options(options) {
				this.setOptions(options);
			}
		},
		methods: {
			trigger(event) {
				if (this.stopPropagation) {
					event.srcEvent.stopPropagation();
				}

				this.$emit(event.type, event);
			},
			setOptions(options) {
				Object.keys(options).forEach((gesture) => {
					// const gestureOptions = options[gesture];

					Object.keys(options[gesture]).forEach((key) => {
						const value = options[gesture][key];

						// if (Hammer.hasOwnProperty(value)) {
						// 	options[gesture][key] = Hammer[value];
                        // }
                        if(value in Hammer) {
                            options[gesture][key] = Hammer[value];
                        }
					});

					this.interaction.get(gesture).set(options[gesture]);
				});
			}, 
			attachListeners(gesture) {
				this.interaction.on(gesture, this.trigger);
			},
			detachListeners(gesture) {
				this.interaction.off(gesture, this.trigger);
			}
		},
		mounted() {
			this.interaction = new Hammer(this.$el, {
				...this.options
			});

			this.setOptions(this.options);

			this.gestures.forEach(gesture => {
				this.attachListeners(gesture);
			});
		},
		beforeDestroy() {
			this.gestures.forEach(gesture => {
				this.detachListeners(gesture);
			});

			if (this.interaction) {
				this.interaction.destroy();
			}
		},
        render() {
            return this.$scopedSlots.default({});
        }
    }
</script>