<template>
    <Touch
        :gestures="['swipeleft', 'swiperight']"
        @swipeleft="next"
        @swiperight="prev"
    >
        <div class="w-full flex flex-col overflow-hidden pt-0">
            <div 
                class="h-full flex-1 min-w-0 flex transition-all duration-300 ease-out"
                :style="{ 'transform': `translateX(-${activeIndex}00%)` }"
            >
                <slot :active="activeIndex" />
            </div>

            <div v-if="dots" class="mt-3 flex justify-center">
                <div 
                    v-for="(item, index) in maxLength" 
                    :key="index"
                    class="ml-3 first:ml-0 w-2 h-2 rounded-full cursor-pointer"
                    :class="{ 
                        'bg-module': index !== activeIndex,
                        'bg-main': index === activeIndex
                    }"
                    @click="goto(index)"
                ></div>
            </div>
        </div>
    </Touch>
</template>

<script>

    import Touch from '@/pages/inside/touch'

    export default {
        name: 'Carousell',
        components: {
            Touch
        },
        props: {
            dots: {
                type: Boolean
            },
            maxLength: {
                type: Number
            },
            defaultActiveIndex: {
                type: Number
            }
        },
        data() {
            return {
                items: [],
                activeIndex: 0
            };
        },
        methods: {
            prev() {
                if (this.activeIndex > 0) {
                    this.activeIndex--;
                } 
                this.headlineSubtitle = 'Enero-Diciembre';
                this.$emit('headLineText', this.headlineSubtitle);
            },
            next() {
                if(this.activeIndex < this.maxLength - 1) {
                    this.activeIndex++;
                }
                this.headlineSubtitle = 'Últimos 30 días';
                this.$emit('headLineText', this.headlineSubtitle);
            },
            goto(activeIndex) {
                this.activeIndex = Math.min(Math.max(activeIndex, 0), this.maxLength - 1);
            }
        },
        mounted() {
            this.items = this.$slots.default;
            if(this.defaultActiveIndex !== undefined){
                this.activeIndex = this.defaultActiveIndex;
            }
        },
        watch:{
            activeIndex(n){
                this.$emit('changeActiveIndex',  n);
            },
            defaultActiveIndex(n){
                this.activeIndex = n;
            }
        }

    }
</script>